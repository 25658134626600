import "popper.js";
import "bootstrap";
import "bootstrap-slider";
import "bootstrap-select";

import "./scripts-includes/tabs";
import "./scripts-includes/toggle";

$(function () {
  // add plugin to jQuery
  $(document).on("initPlugins", function () {});
  $(document).trigger("initPlugins");
});

// MENU
$(function () {
  $(".js-toggle-menu").on("click", function () {
    $(".js-header-menu").removeClass("js-hidden");
    $(".js-side-menu").addClass("js-hidden");
    $(".header__overlay").fadeToggle(200);
  });

  $(".js-show-overlay").on("click", function () {
    if ($(window).width() < 992) {
      $(".js-header-menu").addClass("js-hidden");
      $(".js-side-menu").removeClass("js-hidden");
      $(".header__overlay").fadeIn(200);
    }
  });
});

// js-stick-to-header-top
$(function () {
  if ($(".js-stick-to-header-top").length) {
    $(window)
      .on("resize", function () {
        $(".js-stick-to-header-top").css({
          marginTop: -($(".header").outerHeight(true) - $(".header__top").outerHeight()),
        });
      })
      .trigger("resize");
  }
});
