/**
     EXPAND SOMETHING
     <a href="#"
     data-js-target-id="example"
     data-js-action="toggle (default) | show | hide | toggle-slide | show-slide | hide-slide"
     data-outer-close="true" (optional)
     data-toggle-class="active" (optional)
     >
     Click here
     </a>
     <div data-js-id="example">
     For show this
     </div>
     */
$(document).on("click touchstart", "[data-js-target-id]", function(e) {
  var $self = $(this),
    activeClass = $self.data("toggleClass") || "",
    targetId = $(this).data("jsTargetId"),
    $id = $("[data-js-id = " + targetId + "]"),
    action = $(this).data("jsAction");

  if (
    $(this).data("outerClose") === true &&
    (action === "toggle" || action === undefined || action === "show")
  ) {
    setTimeout(function() {
      $(document).off(".dropdown");
      $(document).on("click.dropdown", function(e) {
        var $clicked = $(e.target);
        if (
          !(
            $clicked.is($self) ||
            $clicked.closest($self).length !== 0 ||
            $clicked.is($id) ||
            $clicked.closest($id).length !== 0
          )
        ) {
          $id.addClass("js-hidden");
          $self.removeClass(activeClass);
          $(document).off(".dropdown");
        }
      });
    }, 50);
  }
  switch (action) {
    case "show":
      $id.removeClass("js-hidden");
      $self.addClass(activeClass);
      break;
    case "show-slide":
      $id.hide().removeClass("js-hidden");
      $self.addClass(activeClass);
      setTimeout(function() {
        $id.slideDown();
      }, 1);
      break;
    case "hide":
      $id.addClass("js-hidden");
      $self.removeClass(activeClass);
      break;
    case "hide-slide":
      $id.slideUp(function() {
        $id.addClass("js-hidden");
      });
      $self.removeClass(activeClass);
      break;
    case "toggle-slide":
      if ($id.hasClass("js-hidden")) {
        $id
          .hide()
          .removeClass("js-hidden")
          .slideDown();
        $self.addClass(activeClass);
      } else {
        $id.slideUp(function() {
          $id.addClass("js-hidden");
        });
        $self.removeClass(activeClass);
        $(document).off(".dropdown");
      }
      break;
    default:
      if ($id.hasClass("js-hidden")) {
        $id.removeClass("js-hidden");
        $self.addClass(activeClass);
      } else {
        $id.addClass("js-hidden");
        $self.removeClass(activeClass);
        $(document).off(".dropdown");
      }
      break;
  }
});
$(document).on("click touchstart", "[data-js-toggle-group]", function(e) {
  var array = $(this).data("jsToggleGroup"),
    allowBubbling = $(this).data("allowBubbling");

  if (Array.isArray(array)) {
    $.each(array, function(index, item) {
      var $id = $("[data-js-id = " + item["id"] + "]"),
        action = item["action"];

      switch (action) {
        case "show":
          $id.removeClass("js-hidden");
          break;
        case "show-slide":
          $id.hide().removeClass("js-hidden");
          setTimeout(function() {
            $id.slideDown();
          }, 1);
          break;
        case "hide":
          $id.addClass("js-hidden");
          break;
        case "hide-slide":
          $id.slideUp(function() {
            $id.addClass("js-hidden");
          });
          break;
        case "toggle-slide":
          if ($id.hasClass("js-hidden")) {
            $id
              .hide()
              .removeClass("js-hidden")
              .slideDown();
          } else {
            $id.slideUp(function() {
              $id.addClass("js-hidden");
            });
          }
          break;
        default:
          $id.toggleClass("js-hidden");
          break;
      }
    });
  }
});
$(document).on("click touchstart", "[data-stop-propagation]", function(e) {
  if ($(this).data("stopPropagation") == true) {
    e.stopPropagation();
  }
});
$(document).on("click touchstart", "[data-prevent-default]", function(e) {
  if ($(this).data("preventDefault") == true) {
    e.preventDefault();
  }
});
