/**
 * TABS
 * set data-r-tab-target="tab-1" data-r-tab-active="item--active" for head item
 * set data-r-tab-group="stat" data-r-tab-id="tab-1" for body item
 * if tabs is a select
 * <select  data-r-tab-target>
    <option value="tab-1">text</option>
    <option value="tab-2">text</option>
    <option value="tab-3">text</option>
   </select>
 */
$(document).on("click change", "[data-r-tab-target]", function(e) {
  let targetId;
  let activeClass;

  if ($(this).is("select")) {
    targetId = $(this).val();
    activeClass = "";
  } else {
    targetId = $(this).data("rTabTarget");
    activeClass = $(this).data("rTabActive") || "";
  }

  const $tab = $("[data-r-tab-id = " + targetId + "]");
  const group = $tab.data("rTabGroup");

  $(this)
    .addClass(activeClass)
    .siblings()
    .removeClass(activeClass);
  $("[data-r-tab-group = " + group + "]").addClass("js-hidden");
  $tab.removeClass("js-hidden");
});
